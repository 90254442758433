/* Ensure the code-toolbar has relative positioning with flexible width */
div.code-toolbar {
    position: relative !important;
    display: inline-block !important;
    max-width: 100% !important;
}
div.code-toolbar > .toolbar {
    position: absolute !important;
    top: 0px !important;
    right: 5px !important;
    transition: opacity 0.3s ease-in-out !important;
    opacity: 0 !important;
    z-index: 10 !important;
    width: auto !important;
    max-width: calc(100% - 10px) !important;
}
div.code-toolbar:hover > .toolbar {
    opacity: 1 !important;
}
.code-toolbar > .toolbar > .toolbar-item {
    max-width: 100% !important;
}

/* Copy button */
.code-toolbar > .toolbar > .toolbar-item > button {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 3px 8px;
}
.code-toolbar > .toolbar > .toolbar-item > button:hover {
    background-color: #34D399;
}

/* Override background color and rounded corners */
:not(pre) > code, pre[class*="language-"] {
    background-color: #06263a !important;
}
:not(pre) > code {
    padding: 0px 5px;
}
div.code-toolbar {
    background-color: #06263a !important;
    padding: 0px 10px 0px 0px;
    border: 1px solid #34D399;
    border-radius: 10px;
    overflow-x: auto;
}
div.code-toolbar > pre {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
pre[class*="language-"].line-numbers {
    padding-left: 3.8em !important;
}
div.line-highlight {
    background: linear-gradient(to right, #34D39966 50%, #06263a);
    width: calc(100% - 25px) !important;
}

/* Scrollbar styling */
pre[class*="language-"]::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: #34D39933;
}
pre[class*="language-"]::-webkit-scrollbar-thumb:horizontal{
    background: #34D399;
}